import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Engine from './Engine.js';
import Home from './Home.js';
import Order from './Order.js';
import Error from './Error.js';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/engine" element={<Engine />} />
        <Route path="/order" element={<Order />} />
        <Route path="/*" element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;