import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image';
import { Showcase } from './Showcase';
import { Manual } from './Manual';
import ListGroup from 'react-bootstrap/ListGroup';
import "bootstrap-icons/font/bootstrap-icons.css";
import { Footer } from "./Footer";
import { Card, Row, Col, CardGroup } from "react-bootstrap";
import "./Home.css"
import { Component, Suspense, useEffect } from "react";
import { useRef } from 'react'
import { Canvas, useFrame, useThree, extend, createRoot, events } from '@react-three/fiber'
import { useGLTF, ContactShadows, Environment, useCubeTexture, PerspectiveCamera} from '@react-three/drei'
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import * as THREE from 'three';
import { Grid } from '@react-three/drei';
import { Spinner } from "react-bootstrap";
import { ARButton, XR } from '@react-three/xr'
import XrHitModel from "./xr-hit-model/XrHitModel";
import { getCountry } from './findCountry';

// Add the orbitControl element from the three.js libs.
// React OrbitContols blocks the UI, that is why we extend another one from three.
extend({ OrbitControls });


// Disable caching.
THREE.Cache.clear();
const texLoader=new THREE.TextureLoader();
texLoader.requestHeader = { 'Cache-Control': 'no-cache, no-store, must-revalidate' };

const CameraControls = () => {
    // Get a reference to the Three.js Camera, and the canvas html element.
    // We need these to setup the OrbitControls class.
    // https://threejs.org/docs/#examples/en/controls/OrbitControls
  
    const {
      camera,
      gl: { domElement },
    } = useThree();
  
    // Ref to the controls, so that we can update them on every frame using useFrame
    const controls = useRef();
    useFrame((state) => controls.current.update());
    return (
      <orbitControls
        ref={controls}
        args={[camera, domElement]}
        enabled={true}
        autoRotate={true}
        autoRotateSpeed={1.5}
        enableZoom={false}
        enableDamping={true}
        dampingFactor={0.05}
        rotateSpeed={0.8}
        enablePan={true}
        maxPolarAngle={Math.PI / 2.2}
      />
    );
  };


function Loading() {
  return (
    <div className="loading">
      <Spinner animation="grow" variant="success" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
}


function Model() {
  const texture = useCubeTexture(["px.png", "nx.png", "py.png", "ny.png", "pz.png", "nz.png"], {path: "envmap/"}, {blur: 1})
  const reflectivity = 1
  let  url = "/OpenAI.Triton.main.glb"
  const { nodes, materials } = useGLTF(url)
  // Get the base of the model and save it in mesh.
  let mesh
  // The last model will be the base, we will save it in mesh var.
  Object.entries(nodes).map( ([key, value]) =>  {
    mesh = value
  })
  // ensure the bounding box is computed for its geometry
  // this should be done only once (assuming static geometries)
  mesh.geometry.computeBoundingBox();
  // Compute the size of the object's bounding box.
  const box = new THREE.Box3().setFromObject(mesh);
  const size = new THREE.Vector3();
  const parm = new THREE.Vector3();
  box.getSize(size);
  // changeFinishLoading(true)
  return (
    <group dispose={null}>
      {
        Object.entries(nodes).map(([key, value]) => {
          return(
            <mesh
              // YZX.
              position={[0, 0, 0]}
              rotation={[0, -Math.PI / 2, 0]}
              scale={1/2}
              receiveShadow
              castShadow
              geometry={value.geometry}
              material={value.material}
            >
              <meshBasicMaterial
                envMap={texture}
                color={"#FFFFFF"}
                reflectivity={reflectivity}
              />
              <meshPhongMaterial
                transparent={true}
                opacity={0.95}
                color={"#416a50"}
                emissive={0x636637}
                specular={"#5203fc"}
                shininess={80}
                reflectivity={reflectivity}
                envMap={texture}
              />
            </mesh>
          )
        })
      }
    </group>
  )
}


function Scene(props) {
  return (
    <>
        <PerspectiveCamera position={[0, 30, 25]} fov={40} near={0.1} far={300} makeDefault/>
        <CameraControls />
        <ambientLight intensity={0.7} castShadow/>
        <spotLight intensity={0.5} angle={10} penumbra={1} position={[100, 150, 50]} castShadow />
        <spotLight intensity={1} angle={10} penumbra={1} position={[100, 50, 150]} castShadow />
        <spotLight intensity={1} angle={10} penumbra={1} position={[-100, 50, -150]} castShadow />
        <Environment files={"kloppenheim_02_puresky_1k.hdr"} background blur={0.8}/>
        <ContactShadows frames={1} position={[0, -3, 0]} opacity={1} scale={150} blur={1} far={10} resolution={256} color="#000000" />
        <fog attach="fog" args={["#00535c", 20, 80]}/>
        <Grid
            scale={100}
            sectionSize={1}
            sectionColor={"#3be2ff"}
            sectionThickness={1}
        />
        <Model/>
    </>
  )
}

function Home() {
  const country = getCountry()
  if (country === "Russia") {
    return (
      <div lang='ru' translate="no">
        <Navbar style={{backgroundColor: "white", fontFamily: "Courier"}}>
          <Container id='HomeNav' style={{padding: "7px"}}>
            <Image
                style={{padding: "0px", border: "4px solid black"}}
                // style={{ filter: 'brightness(0) saturate(100%) invert(100%)' }}
                src="gitprint-logo-white.png"
                alt="gitprint"
                fluid
                width={160}
                // roundedCircle
              />
            <Nav variant='pills' className="justify-content-end" >
              <Nav.Link href="/engine" ><h2 id="Search">Поиск</h2></Nav.Link>
            </Nav>
          </Container>
        </Navbar>
        <Container style={{ padding: "3vw", fontWeight: "bold", fontFamily: "Courier", fontSize: "1em", border: "0.05rem solid #c7c7c7", borderRadius: "10px" }}>
          <br/>
          <h1 style={{ fontWeight: "bold"}}>git to 3D</h1>
          <p>
            Мы меняем то, как разработчики показывают свой вклад в IT. gitprint переводит код в арт-объект, собрав данные из сервисов
            хранения репозиториев будь он <a href='https://github.com/'>github <i className="bi bi-github"></i></a> или
            <a href='https://gitlab.com/'> gitlab <i className="bi bi-gitlab"></i></a>.
          </p>
          <p>
            С помощью gitprint вы можете создать 3D-модель из вашего git не только для пользователя или организации, но и для репозитория или даже для ветки внутри них.            
          </p>
          <br/>
          <Card style={{ backgroundColor: "black", color: "white", padding: "1px" }}>
            <Canvas
              camera={{position: [0, 30, 40], fov: 40, near: 0.1, far: 300}}
              id="CanvasShowModel"
              style={{height: "40vh", borderRadius: "5px"}}
            >
              <Scene/>
            </Canvas>
            <h1 style={{ textAlign: "center", paddingTop: "6px" }}><a href='https://github.com/openai/triton'><span>OpenAI/Triton</span></a></h1>
          </Card>
          <br/>
          <h2>Приложение <i class="bi bi-file-check-fill"></i></h2>
            Отныне вы сможете <i className="bi bi-list-columns-reverse"></i>
          <ul>
            <li>выразить себя как разработчик</li>
            <li>гордиться своими успехами в карьере</li>
            <li>мотивировать себя стать лучше и писать больше</li>
            <li>отмечать достижения с коллегами и укрепить командный дух</li>
            <li>дарить тот самый подарок, которого заслуживает любой разработчик и оставить свой код в чьей-то жизни</li>
            <li>популизировать open-source проекты совершенно новым способом, которого мир еще не видал</li>
            <li>создать музей в честь проектов, которые изменили жизнь компании или весь мир</li>
          </ul>
          <h2>Видеообзор <i className="bi bi-camera-video-fill"></i></h2>
              <div itemScope itemProp='VideoObject' itemType='https://schema.org/VideoObject' style={{margin: "0px", border: "0.05rem solid #c7c7c7", borderRadius: "10px", height: "50vh", width: "100%", overflow: "hidden"}}>  
                <p itemprop="name" hidden>gitprint, git to 3D.</p>
                <p itemprop="description" hidden>gitprint, your virtual code in the physical world! From git to 3D. Download a 3D STL model of your git repository!</p>
                <meta itemProp="contentUrl" content="https://youtu.be/jDjw2vdw-wE?si=M5s17wKzrGoXjxot" />
                <meta itemprop="thumbnailUrl" content="https://gitprint.store:3000/gitprint-logo-white.png" />
                <meta itemprop="uploadDate" content="2024-06-06T08:00:00+08:00" />
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/jDjw2vdw-wE?si=ofRh6m_Z9yOKkG58" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              </div>
          <br/>
          <h2>Пример gitprint <i className="bi bi-bag-heart-fill"></i></h2>
          <Showcase />
          <br/>
          <h2>Инструкция <i className="bi bi-book-fill"></i></h2>
          <ol style={{ marginLeft: "1vw" }}>
            <li>
              <p>
                Первый шаг - выбрать свой git провайдер. Это может быть <a href='https://github.com/'>github <i className="bi bi-github"></i></a> или
                <a href='https://gitlab.com/'> gitlab <i className="bi bi-gitlab"></i></a>.
              </p>
              <Image style={{width: "80%", display: "block", marginLeft: "auto", marginRight: "auto"}} src='/manual/gitList.png'></Image>
            </li>
            <br/>
            <li>
              <p>
                Затем нужно вводить имя пользователя или проекта, из которого вы хотите создать 3D модель. Тут есть несколько вариантов:
              </p>
              <Image style={{width: "80%", display: "block", marginLeft: "auto", marginRight: "auto"}} src='/manual/SearchPrompt.png'></Image>
              <h2>Пользователь</h2>
              <p>Вы можете ввести имя пользователя своего профиля или профиля друга из выбранной платформы системы контроля версии.
              <br/>
              Пример: <code>FrenkenFlores</code></p>
              <h2>Пользователь/Репозитории</h2>
              <p>
              Вы можете ввести название репозитории, который принадлежит пользователю. Например, петпроект, над которым вы давно уже вместе с друзьями работаете.
              <br/>
              Пример: <code>FrenkenFlores/testRepo</code>
              </p>
              <h2>Пользователь/Репозитории/Ветка</h2>
              <p>
              Вы можете выбрать и ветку для любого репозитория. Иногда проект слишком большой, из-за этого ваша команда может работать в отдельной ветке.
              <br/>
              Пример:
              <br/>
              <code style={{fontSize: "0.8em"}}>FrenkenFlores/testRepo/testBranch</code>
              </p>
              <h2>Организация/Репозитории</h2>
              <p>
              Если же проект принадлежит организации, как Google, OpenAI или даже вашей компании, тогда нужно ввести название организации и проекта, относящегося к ней.
              <br/>
              Пример: <code>OpenAI/Triton</code>
              </p>
              <h2>Организация/Репозитории/Ветка</h2>
              <p>
              Пример:
              <br/>
              <code>OpenAI/Triton/gh-pages</code>
              </p>
            </li>
            <br/>
            <li>
              <p>
                Расслабьтесь, пока gitprint собирает ваш арт-объект в течение пару секунд. gitprint создаст вам 3D модель в STL формате, которую можно сразу напечатать.
              </p>
              <Spinner style={{display: "block", marginLeft: "auto", marginRight: "auto"}} animation="border" variant="success" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </li>
            <br/>
            <li>
              <p>
                Возьмите свое время, чтобы прочувствовать и пощупать модель, которая скоро будет украшать ваше рабочее место.
              </p>
              <Image style={{width: "80%", display: "block", marginLeft: "auto", marginRight: "auto", borderRadius: "10px"}} src='/manual/mainW.png'></Image>
            </li>
            <br/>
            <li>
              В нижней части вы найдете панель управления, которая даст вам больше контроля.
              <br/>
              <Image style={{width: "80%", display: "block", marginLeft: "auto", marginRight: "auto"}} src='/manual/BottomPanel.png'></Image>
              <br/>
              Можно выбрать определенный промежуток времени. Например, годы обучения, стаж в крупной известной компании или стартап, над которым вы работали когда-то.
              <br/>
              <Image style={{width: "80%", display: "block", marginLeft: "auto", marginRight: "auto"}} src='/manual/Timeline.png'></Image>
              <br/>
              Нажав кнопку 💌, можно оставить комментарий на своей модели; послание или пожелание на подарке, от которого ваш друг будет в восторге.
              <br/>
              <Image style={{width: "80%", display: "block", marginLeft: "auto", marginRight: "auto"}} src='/manual/Comment.png'></Image>
              <br/>
              Когда вы закончите, нажмите на кнопку <code>Create 🧩</code>.
            </li>
            <br/>
            <li>
              Теперь, когда все готово, что насчет того, чтобы взломать систему и съэкономить? Нет, в этом нет необходимости, gitprint
              реализовал первую систему чести в сфере IT. Нам важнее ваша честь, платите, когда хотите и когда можете. Будьте человеком чести, нажмите кнопку <code>Buy 💳</code>.
              <br/>
              Загрузка вашей 3D модели начнется моментально и спустя пару часов 3D печати вы сможете удивить весь мир!
              <br/>
            </li>
            <br/>
            <li>
              И последнее, если у вас нет 3D принтера, мы здесь для вас. Нажмите на кнопку <code>Print 🚀</code> , а мы изготовим, упакуем и доставим в течение одной недели.
            </li>
          </ol>
          <h3>Оформление заказа <i class="bi bi-fingerprint"></i></h3>
          Мы работаем по всему миру, наши партнеры <code>PCBWay</code> помогают нам глобально обслуживать планету, а мы обслуживаем Россию и стран СНГ.
          <br/>
          Оплату можно провести картой МИР, если вы в России, или картами Visa и MasterCard, если вы за рубежом. 
          <Image style={{width: "80%", display: "block", marginLeft: "auto", marginRight: "auto"}}  src='/pcbway-logo.png'></Image>
          <Image style={{width: "80%", display: "block", marginLeft: "auto", marginRight: "auto"}}  src='/payments.png'></Image>
          <Nav variant='pills' className="justify-content-center" >
            <Nav.Link style={{textAlign: "center"}} id="bottomButton" href="/engine" ><h2 id="Search">Получить свой gitprint!</h2></Nav.Link>
          </Nav>
          <br/>
          <h2>Социальные сети <i className="bi bi-at"></i></h2>
          Оставайтесь на связи, чтобы быть в курсе последних новостей.
          <ListGroup horizontal>
            <ListGroup.Item><a href='https://www.instagram.com/gitprint/'><i className="bi bi-instagram"></i></a></ListGroup.Item>
            <ListGroup.Item><a href='https://www.threads.net/@gitprint'><i className="bi bi-threads"></i></a></ListGroup.Item>
            <ListGroup.Item><a href='https://www.youtube.com/@gitprint'><i className="bi bi-youtube"></i></a></ListGroup.Item>
            <ListGroup.Item><a href='https://t.me/gitprint'><i className="bi bi-telegram"></i></a></ListGroup.Item>
            <ListGroup.Item><a href='https://www.linkedin.com/company/101332795/'><i className="bi bi-linkedin"></i></a></ListGroup.Item>
            <ListGroup.Item><a href='mailto:info@gitprint.store'><i className="bi bi-at"></i></a></ListGroup.Item>
          </ListGroup>
          <br/>
        </Container >
        <Container style={{padding: "0px"}}>
          <Card className="bg-black text-white" style={{ marginBottom: "12px", marginTop: "8px" }}>
            <Footer />
          </Card>
        </Container>
      </div>
    );
  }
  return (
      <div lang='en'>
        <Navbar style={{backgroundColor: "white", fontFamily: "Courier"}}>
          <Container id='HomeNav' style={{padding: "7px"}}>
            <Image
                style={{padding: "0px", border: "4px solid black"}}
                // style={{ filter: 'brightness(0) saturate(100%) invert(100%)' }}
                src="gitprint-logo-white.png"
                alt="gitprint"
                fluid
                width={160}
                // roundedCircle
              />
            <Nav variant='pills' className="justify-content-end" >
              <Nav.Link href="/engine" ><h2 id="Search">Search</h2></Nav.Link>
            </Nav>
          </Container>
        </Navbar>
        <Container style={{ padding: "3vw", fontWeight: "bold", fontFamily: "Courier", fontSize: "1em", border: "0.05rem solid #c7c7c7", borderRadius: "10px" }}>
          <br/>
          <h1 style={{ fontWeight: "bold"}}>git to 3D</h1>
          <p>
            We revolutionize the way developers showcase their digital masterpiece
            by turning their coding journey into a work of art. gitprint creates a 3D printable heatmap
            from the git cloud data, be it <a href='https://github.com/'>github <i className="bi bi-github"></i></a> or
            <a href='https://gitlab.com/'> gitlab <i className="bi bi-gitlab"></i></a>.
          </p>
          <p>
            With gitprint you can create a 3D model from your git not only for a user or an organization, but also for a repository or even for a branch within it.
          </p>
          <br/>
          <Card style={{ backgroundColor: "black", color: "white", padding: "1px" }}>
            <Canvas
              camera={{position: [0, 30, 40], fov: 40, near: 0.1, far: 300}}
              id="CanvasShowModel"
              style={{height: "40vh", borderRadius: "5px"}}
            >
              <Scene/>
            </Canvas>
            <h1 style={{ textAlign: "center", paddingTop: "6px" }}><a href='https://github.com/openai/triton'><span>OpenAI/Triton</span></a></h1>
          </Card>
          <br/>
          <h2>Application <i class="bi bi-file-check-fill"></i></h2>
          From now on you have the right to <i className="bi bi-list-columns-reverse"></i>
          <ul>
            <li>express your self as a developer</li>
            <li>embrace your journey and motivate yourself</li>
            <li>celebrate milestones with your teammates and make a stronger friendship</li>
            <li>give the gift that every developer deserves and leave your code in someone's life</li>
            <li>build an open-source museum with the top open-source projects</li>
            <li>perpetuate the memory of the projects that changed the world</li>
          </ul>
          <h2>Intro <i className="bi bi-camera-video-fill"></i></h2>
              <div itemScope itemProp='VideoObject' itemType='https://schema.org/VideoObject' style={{margin: "0px", border: "0.05rem solid #c7c7c7", borderRadius: "10px", height: "50vh", width: "100%", overflow: "hidden"}}>  
                <p itemprop="name" hidden>gitprint, git to 3D.</p>
                <p itemprop="description" hidden>gitprint, your virtual code in the physical world! From git to 3D. Download a 3D STL model of your git repository!</p>
                <meta itemProp="contentUrl" content="https://youtu.be/jDjw2vdw-wE?si=M5s17wKzrGoXjxot" />
                <meta itemprop="thumbnailUrl" content="https://gitprint.store:3000/gitprint-logo-white.png" />
                <meta itemprop="uploadDate" content="2024-06-06T08:00:00+08:00" />
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/jDjw2vdw-wE?si=ofRh6m_Z9yOKkG58" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              </div>
          <br/>
          <h2>Showcase <i className="bi bi-bag-heart-fill"></i></h2>
          <Showcase />
          <br/>
          <h2>Manual <i className="bi bi-book-fill"></i></h2>
          <ol style={{ marginLeft: "1vw" }}>
            <li>
              <p>
                The first step is to choose the git repo hosting service.
              </p>
              <p>
              You can choose GitHub or GitLab. Also, you can add your own repo. <span style={{ color: "grey"}}>(comming soon)</span>
              </p>
              <Image style={{width: "80%", display: "block", marginLeft: "auto", marginRight: "auto"}} src='/manual/gitList.png'></Image>
            </li>
            <br/>
            <li>
              <p>
                After choosing your source code manager you can start writing.
              </p>
              <Image style={{width: "80%", display: "block", marginLeft: "auto", marginRight: "auto"}} src='/manual/SearchPrompt.png'></Image>
              <h2>User</h2>
              <p>It might be your username or the username of your friend.
              <br/>
              For example: <code>FrenkenFlores</code></p>
              <h2>User/Repo</h2>
              <p>
              You can also specify a repo. It might be a specific project you and your friends were working on.
              <br/>
              For example: <code>FrenkenFlores/testRepo</code>
              </p>
              <h2>User/Repo/Branch</h2>
              <p>
              Or even a specific branch within it. Sometimes the project is big enough and your team might use there own branch.
              <br/>
              For example:
              <br/>
              <code style={{fontSize: "0.8em"}}>FrenkenFlores/testRepo/testBranch</code>
              </p>
              <h2>Org/Repo</h2>
              <p>
              If the repo belongs to an orgranization, like Google, OpenAI or your own company, then specify the organization name and the repo that you are looking for.
              <br/>
              For example: <code>OpenAI/Triton</code>
              </p>
              <h2>Org/Repo/Branch</h2>
              <p>
              The same thing that is valid for a users repo is valid for an organization repo, that means you still can choose the branch that you want.
              <br/>
              For example:
              <br/>
              <code>OpenAI/Triton/gh-pages</code>
              </p>
            </li>
            <br/>
            <li>
              <p>
                Rest for a few seconds and let gitprint do the work. It will do the math and create your 3D printable model in STL format.
              </p>
              <Spinner style={{display: "block", marginLeft: "auto", marginRight: "auto"}} animation="border" variant="success" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </li>
            <br/>
            <li>
              <p>
                After a few seconds your git 3D model will be ready for printing. Take your time to admire your coding journey.
              </p>
              <Image style={{width: "80%", display: "block", marginLeft: "auto", marginRight: "auto", borderRadius: "10px"}} src='/manual/mainW.png'></Image>
            </li>
            <br/>
            <li>
              Scroll down to the bottom to get more control over the model.
              <br/>
              <Image style={{width: "80%", display: "block", marginLeft: "auto", marginRight: "auto"}} src='/manual/BottomPanel.png'></Image>
              <br/>
              Using the following panel you can choose a specific timeline. Let's say you want to remember your old coding days, for example.
              <br/>
              <Image style={{width: "80%", display: "block", marginLeft: "auto", marginRight: "auto"}} src='/manual/Timeline.png'></Image>
              <br/>
              What about self-expression? or leaving a comment or a message on the gift that will make your developer friend amazed? Nothing impossible about it, just click the 💌 button and start writing.
              <br/>
              <Image style={{width: "80%", display: "block", marginLeft: "auto", marginRight: "auto"}} src='/manual/Comment.png'></Image>
              <br/>
              Once you specify the timeline and write your message click the <code>Create 🧩</code> button.
            </li>
            <br/>
            <li>
              When every thing is ready, it is time to hack the system so you won't pay the price. Why? gitprint implements the first honor system in the digital world, we don't want your coins, we want your honor. Pay when you can and when you want. Be a man of honor and click the <code>Buy 💳</code> button.
              <br/>
              <br/>
              After clicking the <code>Buy 💳</code> button the printable 3D model will be downloaded instantly. Use the photopolymer 3D printer for the best results.
              <br/>
              <br/>
              A few hours later you will be honored with your first gitprint so you can show the world what you are made of!
            </li>
            <br/>
            <li>
              Finally, If you don't want to strugle with the 3D printer or you don't have one, don't worry you can order the printed model by clicking the <code>Print 🚀</code> button, you will get redirected instantly to the order page.
            </li>
          </ol>
          <h3>Ordering and payment <i class="bi bi-fingerprint"></i></h3>
          If you choose to order the model by clicking the <code>Print 🚀</code> button, you will be redirected to our partners page, that is <code>PCBWay</code>,
          they allow you to choose almost any material that you want for 3D printing, including metal. They have the lowest prices and a greate delivery service that normally takes 3-7 business days for the gitprint to be delivered around the world.
          <br/>
          Those who can't access <code>PCBWay</code> will be redirected to our local service.
          <Image style={{width: "80%", display: "block", marginLeft: "auto", marginRight: "auto"}}  src='/pcbway-logo.png'></Image>
          <Image style={{width: "80%", display: "block", marginLeft: "auto", marginRight: "auto"}}  src='/payments.png'></Image>
          <Nav variant='pills' className="justify-content-center" >
            <Nav.Link style={{textAlign: "center"}} id="bottomButton" href="/engine" ><h2 id="Search">Go get your gitprint!</h2></Nav.Link>
          </Nav>
          <br/>
          <h2>Social media <i className="bi bi-at"></i></h2>
          More showcases and updates will be uploaded to the social media, stay in touch.
          
          <ListGroup horizontal>
            <ListGroup.Item><a href='https://www.instagram.com/gitprint/'><i className="bi bi-instagram"></i></a></ListGroup.Item>
            <ListGroup.Item><a href='https://www.threads.net/@gitprint'><i className="bi bi-threads"></i></a></ListGroup.Item>
            <ListGroup.Item><a href='https://www.youtube.com/@gitprint'><i className="bi bi-youtube"></i></a></ListGroup.Item>
            <ListGroup.Item><a href='https://t.me/gitprint'><i className="bi bi-telegram"></i></a></ListGroup.Item>
            <ListGroup.Item><a href='https://www.linkedin.com/company/101332795/'><i className="bi bi-linkedin"></i></a></ListGroup.Item>
            <ListGroup.Item><a href='mailto:info@gitprint.store'><i className="bi bi-at"></i></a></ListGroup.Item>
          </ListGroup>
          <br/>
        </Container >
        <Container style={{padding: "0px"}}>
          <Card className="bg-black text-white" style={{ marginBottom: "12px", marginTop: "8px" }}>
            <Footer />
          </Card>
        </Container>
      </div>
  );
}
useGLTF.preload('/OpenAI.Triton.main.glb')
export default Home;