import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';


function Error() {
  return (

    <Card className="text-center" style={{ position: "absolute", top: "50%", left: "50%", marginRight: "-50%", transform: `translate(-50%, -50%)`}}>
      <a href='https://gitprint.store'>
        <Card.Img style={{width: "100%", display: "block", marginLeft: "auto", marginRight: "auto"}} variant="top" src="gitprint-logo-black.png" />
      </a>
      <Card.Body>
        <Card.Title><h1><code>404</code></h1></Card.Title>
        <Card.Text>
          Page not found! 💔
        </Card.Text>
        <Button variant="success" onClick={() => { window.location.assign('/') }}>Get back to gitprint! ❤️‍🩹</Button>
      </Card.Body>
    </Card>
  );
}
export default Error;