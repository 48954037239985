import React, { useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import * as THREE from 'three';

// export default function Model(props) {
//   const group = useRef();
//   const { nodes, materials, animations } = useGLTF("/FrenkenFlores.glb");
//   const { actions } = useAnimations(animations, group);
//   return (
//     <group ref={group} {...props} dispose={null}>
//       <group scale={1.91}>
//         <primitive object={nodes.root} />
//         <skinnedMesh
//           geometry={nodes.FrenkenFlores.geometry}
//           material={materials.color_main}
//           skeleton={nodes.FrenkenFlores.skeleton}
//         />
//       </group>
//     </group>
//   );
// }


// export default function Model(props) {
//   const group = useRef();
//   const { nodes, materials } = useGLTF("/FrenkenFlores.glb")
//   // Get the base of the model and save it in mesh.
//   let mesh
//   // The last model will be the base, we will save it in mesh var.
//   Object.entries(nodes).map( ([key, value]) =>  {
//     mesh = value
//   })
//   // Compute the size of the object's bounding box.
//   const box = new THREE.Box3().setFromObject(mesh);
//   const size = new THREE.Vector3();
//   box.getSize(size);
//   // changeFinishLoading(true)
//   return (
//     <group ref={group} {...props} dispose={null}>
//       {
//         Object.entries(nodes).map(([key, value]) => {
//           return(
//             <mesh
//               // YZX.
//               rotation={[0, 3 * Math.PI / 2, 0]}
//               scale={0.002}
//               receiveShadow
//               castShadow
//               geometry={value.geometry}
//             >
//               <meshMatcapMaterial
//                 color={"#00FF00"}
//               />
//             </mesh>
//           )
//         })
//       }
//     </group>
//   )
// }


// useGLTF.preload("/FrenkenFlores.glb");

// import { useLoader } from "@react-three/fiber";
// import { Suspense } from "react";
// import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

// const Model = ({ position }) => {
//   const gltf = useLoader(GLTFLoader, "/models/druid.gltf");
//   return (
//     <Suspense fallback={null}>
//       <primitive position={position} object={gltf.scene} />
//     </Suspense>
//   );
// };

// export default Model;




export default function Model(props) {
  const group = useRef();
  let  url;
  if (props.branch.length && (props.branch === "master" || props.branch === "main")) {
    url = `https://storage.yandexcloud.net/gitprint-container/${props.name.replaceAll("/", ".")}.${props.branch}.glb?var=${props.modelVersion}`
  } else {
    url = `https://storage.yandexcloud.net/gitprint-container/${props.name.replaceAll("/", ".")}.glb?var=${props.modelVersion}`
  }
  const { nodes, materials } = useGLTF(url)
  // Get the base of the model and save it in mesh.
  let mesh
  // The last model will be the base, we will save it in mesh var.
  Object.entries(nodes).map( ([key, value]) =>  {
    mesh = value
  })
  // Compute the size of the object's bounding box.
  const box = new THREE.Box3().setFromObject(mesh);
  const size = new THREE.Vector3();
  box.getSize(size);
  return (
    <group ref={group} {...props} dispose={null}>
      {
        Object.entries(nodes).map(([key, value]) => {
          return(
            <mesh
              // YZX.
              rotation={[0, 3 * Math.PI / 2, 0]}
              scale={0.002}
              receiveShadow
              castShadow
              geometry={value.geometry}
            >
              <meshMatcapMaterial
                color={"#00AF64"}
              />
            </mesh>
          )
        })
      }
    </group>
  )
}














// export default function Model(props) {
//   const group = useRef();
//   let  url;
//   if (props.branch.length && (props.branch === "master" || props.branch === "main")) {
//     url = `/tmp_models/${props.name.replaceAll("/", ".")}.${props.branch}.glb`
//   } else {
//     url = `/tmp_models/${props.name.replaceAll("/", ".")}.glb`
//   }
//   const { nodes, materials } = useGLTF(url)
//   // Get the base of the model and save it in mesh.
//   let mesh
//   // The last model will be the base, we will save it in mesh var.
//   Object.entries(nodes).map( ([key, value]) =>  {
//     mesh = value
//   })
//   // Compute the size of the object's bounding box.
//   const box = new THREE.Box3().setFromObject(mesh);
//   const size = new THREE.Vector3();
//   box.getSize(size);
//   return (
//     <group ref={group} {...props} dispose={null}>
//       {
//         Object.entries(nodes).map(([key, value]) => {
//           return(
//             <mesh
//               // YZX.
//               rotation={[0, 3 * Math.PI / 2, 0]}
//               scale={0.002}
//               geometry={value.geometry}
//             >
//               <meshMatcapMaterial
//                 color={"#00AF64"}
//               />
//             </mesh>
//           )
//         })
//       }
//     </group>
//   )
// }