import './Engine.css';
// import { Search } from './Search'
// import { ModelView } from './ModelView'
import { Component, Suspense, lazy } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import moment from 'moment';
import Image from 'react-bootstrap/Image';


const ModelView = lazy(() => import('./ModelView.js'))
const Search = lazy(() => import('./Search.js'))

class Engine extends Component {
  constructor(prop) {
    super(prop)
    // The ModelView will be the component that
    // will be showing the 3D model using the three.js.
    // It is hidden by default, but when the middle search
    // element turns to top search element, then the ModelView
    // should become visible.
    this.state = {
      hideModelView: true,
      user: "",
      selectMethod: "github",
      startDate: `${moment().subtract(1, 'year').format("YYYY-MM-DD")}`,
      endDate: `${moment().format("YYYY-MM-DD")}`,
      setLoadingState: false,
      gitRepoUploadedDir: "",
      modelIsLoaded: false,
      finishLoading: true,
      modelVersion: 0,
      showToastError: false,
      toastErrorMessage: "",
      repo: "",
      branch: "",
      arEnabled: false,
      infoEnabled: false
    }
    this.changeModelViewState = this.changeModelViewState.bind(this)
    this.changeUserState = this.changeUserState.bind(this)
    this.changeDate = this.changeDate.bind(this)
    this.resetDate = this.resetDate.bind(this)
    this.changeLoadingState = this.changeLoadingState.bind(this)
    this.changeSelectMethod = this.changeSelectMethod.bind(this)
    this.changeGitRepoUploadedDir = this.changeGitRepoUploadedDir.bind(this)
    this.changeModelIsLoaded = this.changeModelIsLoaded.bind(this)
    this.changeFinishLoading = this.changeFinishLoading.bind(this)
    this.incrementModelVersion = this.incrementModelVersion.bind(this)
    this.setToastError = this.setToastError.bind(this)
    this.setRepo = this.setRepo.bind(this)
    this.setBranch = this.setBranch.bind(this)
    this.resetAll = this.resetAll.bind(this)
    this.setArEnabled = this.setArEnabled.bind(this)
    this.setInfoEnabled = this.setInfoEnabled.bind(this)
  }
  render() {
    // Use the dotenv package to handle tokens.
    return (
      <div className='Engine'>
        {this.state.hideModelView &&
          <Container fluid >
            <div className="EngineInit">
              <Suspense fallback={
                <div>
                  <Image
                    style={{padding: "10px"}}
                    // style={{ filter: 'brightness(0) saturate(100%) invert(100%)' }}
                    src="gitprint-logo-white.png"
                    alt="gitprint"
                    fluid
                    width={260}
                    height={260}
                    // roundedCircle
                  />
                </div>
              }>
                <Search 
                  onChangeModelViewState={this.changeModelViewState}
                  onChangeUserState={this.changeUserState}
                  user={this.state.user}
                  onChangeLoadingState={this.changeLoadingState}
                  onChangeSelectMethodState={this.changeSelectMethod}
                  selectMethod={this.state.selectMethod}
                  changeGitRepoUploadedDir={this.changeGitRepoUploadedDir}
                  modelIsLoaded={this.state.modelIsLoaded}
                  changeModelIsLoaded={this.changeModelIsLoaded}
                  changeFinishLoading={this.changeFinishLoading}
                  incrementModelVersion={this.incrementModelVersion}
                  resetDate={this.resetDate}
                  setToastError={this.setToastError}
                  setRepo={this.setRepo}
                  setBranch={this.setBranch}
                  resetAll={this.resetAll}
                  setInfoEnabled={this.setInfoEnabled}
                />
              </Suspense>
            </div>
          </Container>
            ||
          <Container>
            <div className="EngineLaunch">
                <Suspense fallback={<h1>Loading</h1>}>
                  <Search
                    onChangeModelViewState={this.changeModelViewState}
                    onChangeUserState={this.changeUserState}
                    onChangeLoadingState={this.changeLoadingState}
                    onChangeSelectMethodState={this.changeSelectMethod}
                    selectMethod={this.state.selectMethod}
                    changeGitRepoUploadedDir={this.changeGitRepoUploadedDir}
                    modelIsLoaded={this.state.modelIsLoaded}
                    changeModelIsLoaded={this.changeModelIsLoaded}
                    changeFinishLoading={this.changeFinishLoading}
                    incrementModelVersion={this.incrementModelVersion}
                    resetDate={this.resetDate}
                    setToastError={this.setToastError}
                    setRepo={this.setRepo}
                    setBranch={this.setBranch}
                    resetAll={this.resetAll}
                    setInfoEnabled={this.setInfoEnabled}
                  />
                </Suspense>
                <main>
                  <Suspense fallback={<h1>Loading</h1>}>
                    <ModelView
                      onChangeModelViewState={this.changeModelViewState}
                      user={this.state.user}
                      receiveUpdates={this.state.hideModelView}
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      changeDate={this.changeDate}
                      changeLoadingState={this.changeLoadingState}
                      setLoadingState={this.state.setLoadingState}
                      selectMethod={this.state.selectMethod}
                      gitRepoUploadedDir={this.state.gitRepoUploadedDir}
                      modelIsLoaded={this.state.modelIsLoaded}
                      changeModelIsLoaded={this.changeModelIsLoaded}
                      changeFinishLoading={this.changeFinishLoading}
                      incrementModelVersion={this.incrementModelVersion}
                      modelVersion={this.state.modelVersion}
                      setToastError={this.setToastError}
                      repo={this.state.repo}
                      branch={this.state.branch}
                      setArEnabled={this.setArEnabled}
                      arEnabled={this.state.arEnabled}
                      infoEnabled={this.state.infoEnabled}
                      finishLoading={this.state.finishLoading}
                    />
                  </Suspense>
                </main>
                </div>
            </Container>
        }
        {
          (
            !this.state.finishLoading
          ) &&
            <div className="loading" >
                  {/* <img style={{ width: "20%", boxShadow: "0px 0px 0px #DDDDDD"}} src='gitprint-logo.gif' alt="loading..." /> */}
                <Spinner animation="border" variant="success" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        }
      </div>
    );
  }
  // This function will be passed to the Search component,
  // then it will be called from within the Search class once
  // the mid search element becomes invisible.
  changeModelViewState(invisible) {
    this.setState({hideModelView: invisible})
  }

  changeUserState(name) {
    this.setState({user: name})
  }

  changeGitRepoUploadedDir(path) {
    this.setState({gitRepoUploadedDir: path})
  }

  changeSelectMethod(method) {
    this.setState({selectMethod: method})
  }

  changeDate({startDate, endDate}) {
    if (startDate) {
      this.setState(
        {
          startDate: startDate,
          endDate: this.state.endDate
        }
      )
    } else if (endDate) {
      this.setState(
        {
          startDate: this.state.startDate,
          endDate: endDate
        }
      )
    }
  }
  // Activate on generate button click.
  changeLoadingState(state) {
    this.setState({setLoadingState: state})
  }

  changeModelIsLoaded(state) {
    this.setState({modelIsLoaded: state})
  }

  changeFinishLoading(state) {
    this.setState({finishLoading: state})
  }

  incrementModelVersion() {
    this.setState({modelVersion: this.state.modelVersion + 1})
  }

  resetDate() {
    this.setState(
      {
        startDate: `${moment().subtract(1, 'year').format("YYYY-MM-DD")}`,
        endDate: `${moment().format("YYYY-MM-DD")}`
      }
    )
  }

  setToastError(showToastError, toastErrorMessage) {
    this.setState(
      {
        showToastError: showToastError,
        toastErrorMessage: toastErrorMessage
      }
    );
  }

  setBranch(branch) {
    this.setState({branch: branch})
  }

  setRepo(repo) {
    this.setState({repo: repo})
  }
  
  resetAll() {
    this.setState({
      hideModelView: true,
      user: "",
      selectMethod: this.state.selectMethod,
      startDate: `${moment().subtract(1, 'year').format("YYYY-MM-DD")}`,
      endDate: `${moment().format("YYYY-MM-DD")}`,
      setLoadingState: false,
      gitRepoUploadedDir: "",
      modelIsLoaded: false,
      finishLoading: true,
      showToastError: false,
      toastErrorMessage: "",
      repo: "",
      branch: ""
    })
  }

  setArEnabled() {
    this.setState({arEnabled: !this.state.arEnabled})
  }

  setInfoEnabled() {
    this.setState({infoEnabled: !this.state.infoEnabled})
  }
}

export default Engine;
