import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';
import { useState } from 'react';

function Showcase() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };
  return (
    <Carousel
        interval={null}
        fade
        data-bs-theme="white"
        activeIndex={index}
        onSelect={handleSelect}
        // style={{padding: "5px", border: "0.1rem solid #c7c7c7", borderRadius: "15px"}}
    >
      <Carousel.Item>
        <Image
            thumbnail
            className="d-block w-100"
            style={{borderRadius: "10px"}}
            // style={{ filter: 'brightness(0) saturate(100%) invert(100%)' }}
            src="showcase/c0.jpg"
            alt="gitprint"
            fluid
            width={160}
            height={160}
            // roundedCircle
        />
      </Carousel.Item>
      <Carousel.Item>
        <Image
            thumbnail
            className="d-block w-100"
            style={{borderRadius: "10px"}}
            // style={{ filter: 'brightness(0) saturate(100%) invert(100%)' }}
            src="showcase/c1.jpg"
            alt="gitprint"
            fluid
            width={160}
            height={160}
            // roundedCircle
        />
      </Carousel.Item>
      <Carousel.Item>
        <Image
            thumbnail
            className="d-block w-100"
            style={{borderRadius: "10px"}}
            // style={{ filter: 'brightness(0) saturate(100%) invert(100%)' }}
            src="showcase/c2.jpg"
            alt="gitprint"
            fluid
            width={160}
            height={160}
            // roundedCircle
        />
      </Carousel.Item>
      <Carousel.Item>
        <Image
            thumbnail
            className="d-block w-100"
            style={{borderRadius: "10px"}}
            // style={{ filter: 'brightness(0) saturate(100%) invert(100%)' }}
            src="showcase/c3.jpg"
            alt="gitprint"
            fluid
            width={160}
            height={160}
            // roundedCircle
        />
      </Carousel.Item>
      <Carousel.Item>
        <Image
            thumbnail
            className="d-block w-100"
            style={{borderRadius: "10px"}}
            // style={{ filter: 'brightness(0) saturate(100%) invert(100%)' }}
            src="showcase/c4.jpg"
            alt="gitprint"
            fluid
            width={160}
            height={160}
            // roundedCircle
        />
      </Carousel.Item>
      <Carousel.Item>
        <Image
            thumbnail
            className="d-block w-100"
            style={{borderRadius: "10px"}}
            // style={{ filter: 'brightness(0) saturate(100%) invert(100%)' }}
            src="showcase/c5.jpg"
            alt="gitprint"
            fluid
            width={160}
            height={160}
            // roundedCircle
        />
      </Carousel.Item>
      <Carousel.Item>
        <Image
            thumbnail
            className="d-block w-100"
            style={{borderRadius: "10px"}}
            // style={{ filter: 'brightness(0) saturate(100%) invert(100%)' }}
            src="showcase/c6.jpg"
            alt="gitprint"
            fluid
            width={160}
            height={160}
            // roundedCircle
        />
      </Carousel.Item>
      <Carousel.Item>
        <Image
            thumbnail
            className="d-block w-100"
            style={{borderRadius: "10px"}}
            // style={{ filter: 'brightness(0) saturate(100%) invert(100%)' }}
            src="showcase/c7.jpg"
            alt="gitprint"
            fluid
            width={160}
            height={160}
            // roundedCircle
        />
      </Carousel.Item>
    </Carousel>
  );
}

export { Showcase };