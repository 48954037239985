import { useState, useEffect } from "react";
import { Stack } from "react-bootstrap";
import Image from 'react-bootstrap/Image';


function Footer() {
    const [isWideScreen, setIsWideScreen] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            setIsWideScreen(window.innerWidth >= 575); // Set the threshold width as per your requirement
        };
    
        // Add event listener to handle window resize
        window.addEventListener('resize', handleResize);
    
        // Initial check for the screen width on component mount
        handleResize();
    
        // Cleanup the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
        }, []);
    return(
        <Stack
            direction={isWideScreen ? "horizontal" : "vertical"}
            gap={1}
            style={{padding: "20px"}}
        >
            <div>
                <Image
                    style={{padding: "10px"}}
                    // style={{ filter: 'brightness(0) saturate(100%) invert(100%)' }}
                    src="gitprint-logo-white.png"
                    alt="gitprint"
                    fluid
                    width={260}
                    height={260}
                    // roundedCircle
                />
            </div>
            <div>
                <div>
                    <a href='mailto:info@gitprint.store'><span style={{fontFamily: "Courier", fontWeight: "bold"}}>info@gitprint.store</span></a>
                    <br/>
                    <a href='mailto:partnership@gitprint.store'><span style={{fontFamily: "Courier", fontWeight: "bold"}}>partnership@gitprint.store</span></a>
                </div>
                <br/>
                <div>
                    © 2023. All Rights Reserved.
                </div>
            </div>
        </Stack>
    )
}

export { Footer }