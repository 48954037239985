import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { Card, Row, Col, CardGroup } from "react-bootstrap";

export default function Order() {
    return (
        <CardGroup>
            <Card>
                <Card.Img variant="top" src="/order/size_resin.jpg" />
                <Card.Body>
                <Card.Title>Модель из фотополимерной смолы (Resin)</Card.Title>
                <br/>
                <Card.Text>
                Элегантность и роскошь в каждой детали модели из смолы фотополимерного принтера. Искусно проработанные элементы и гладкая поверхность создадут неповторимый восторг в вашем рабочем пространстве. Выделяйтесь из толпы с помощью утонченного сувенира из смолы - он подчеркнет ваш индивидуальный стиль и вкус.
                </Card.Text>
                <Button onClick={ () => {
                    window.open('https://forms.yandex.ru/cloud/6649358890fa7b77e0556834/') 
                }} variant="success">Выбрать модель из фотополимерной смолы</Button>
                </Card.Body>
                <Card.Footer>
                <small className="text-muted">2000₽</small>
                </Card.Footer>
            </Card>
            <Card>
                <Card.Img variant="top" src="/order/size_pla.png" />
                <Card.Body>
                <Card.Title>Модель из PLA пластика</Card.Title>
                <br/>
                <Card.Text>
                Необычный сувенир из PLA пластика - легкий, экологически безопасный и стильный. Идеальное дополнение для любого рабочего стола разработчика. Высокое качество печати и яркие цвета делают эту модель неповторимой. Выберите PLA пластик для оригинального подарка или украшения пространства - оно не оставит вас равнодушным!
                </Card.Text>
                <Button onClick={ () => {
                    window.open('https://forms.yandex.ru/cloud/6619ee6443f74f56f73b8ac0/')
                }} variant="success">Выбрать модель из PLA пластка</Button>
                </Card.Body>
                <Card.Footer>
                <small className="text-muted">1000₽</small>
                </Card.Footer>
            </Card>
        </CardGroup>
    )
};




